import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner"},[_c(VCard,{staticClass:"auth-card"},[_c(VCardTitle,{staticClass:"d-flex align-center justify-center py-7"},[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/"}},[_c(VImg,{staticClass:"me-3 ",attrs:{"src":_vm.appLogo,"max-height":"30px","max-width":"30px","alt":"logo","contain":""}}),_c('h2',{staticClass:"text-2xl font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.appName)+" ")])],1)],1),_c(VCardText,[_c('p',{staticClass:"text-2xl font-weight-semibold text--primary mb-2"},[_vm._v(" Forgot Password? 🔒 ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Enter your email and we'll send you instructions to reset your password ")])]),_c(VCardText,[_c(VForm,[_c(VTextField,{staticClass:"mb-4",attrs:{"outlined":"","label":"Email","placeholder":"john@example.com","hide-details":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VBtn,{attrs:{"block":"","color":"primary"}},[_vm._v(" Send reset link ")])],1)],1),_c(VCardActions,{staticClass:"d-flex justify-center align-center"},[_c('router-link',{staticClass:"d-flex align-center text-sm",attrs:{"to":{name:'auth-login-v1'}}},[_c(VIcon,{attrs:{"size":"24","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronLeft)+" ")]),_c('span',[_vm._v("Back to login")])],1)],1)],1)],1),_c('img',{staticClass:"auth-mask-bg",attrs:{"height":"190","src":require(("@/assets/images/misc/mask-" + (_vm.$vuetify.theme.dark ? 'dark':'light') + ".png"))}}),_c(VImg,{staticClass:"auth-tree",attrs:{"width":"247","height":"185","src":require("@/assets/images/misc/tree.png")}}),_c(VImg,{staticClass:"auth-tree-3",attrs:{"width":"377","height":"289","src":require("@/assets/images/misc/tree-3.png")}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }